.filter-shadow
	position: fixed
	top: 0
	left: 0
	height: 100%
	width: 100%
	background: rgba(0, 0, 0, 0.3)
	opacity: 0
	transition: opacity ease .3s
	pointer-events: none
	z-index: 3

	&.active
		pointer-events: auto
		opacity: 1



.filter
	position: fixed
	top: 0
	right: 0
	background: #fff
	height: 100vh
	width: 320px
	overflow: auto
	transform: translateX(1000px)
	transition: transform ease .3s
	z-index: 1001

	&.active
		transform: translateX(0)

	&__header
		display: flex
		align-items: center
		justify-content: space-between
		padding: 16px

		.btn
			padding: 0
			line-height: 17px
			width: max-content

	&__title
		text-transform: uppercase

	&__btn
		padding: 0 16px

	+max-screen(767px)
		width: 100%

		&__title
			position: relative
			padding-left: 32px

			&::after,
			&::before
				content: ""
				position: absolute
				width: 24px
				height: 1px
				background: #000
				top: 50%
				left: 0

			&::after
				transform: rotate(45deg) translateY(-50%)

			&::before
				transform: rotate(-45deg) translateY(-50%)

		.accordeon
			max-width: 100%
