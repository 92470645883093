.page--thank-you
	margin-bottom: 20px



.section-info
	width: 100%
	max-width: 288px
	margin: auto

	p
		text-align: center

	&__title,
	&__description
		text-align: center

	&__title
		color: $green
		margin-bottom: 24px

	&__description
		font-family: $ffs
		font-size: 16px
		line-height: 24px
		margin-bottom: 36px

	&__row
		display: flex
		align-items: center
		justify-content: center
		margin-bottom: 32px

		&:last-child
			margin-bottom: 0

	&__tab
		font-family: $ffb
		font-size: 24px
		line-height: 24px
		margin-right: 32px

		&:hover
			color: $gray

		&:last-child
			margin-right: 0

	&__form
		margin-bottom: 32px

	&__footer
		padding-top: 32px
		border-top: 1px solid #EAEFF2

		.btn
			padding: 0
			width: max-content
			margin-right: 20px

			&:last-child
				margin-right: 0

			&:hover
				opacity: .7

	.checkbox
		margin-bottom: 32px

	&--flowers
		padding: 160px 0
		position: relative

		&::after,
		&::before
			content: ""
			position: absolute

		&::before
			width: 177px
			height: 56px
			top: 45px
			right: -8px
			background: no-repeat url("/img/green-flowers.png")

		&::after
			width: 186px
			height: 100px
			bottom: 25px
			left: -24px
			background: no-repeat url("/img/green-flowers1.png")

	&--error
		padding-top: 40px

		&::after,
		&::before
			content: none

		.section-info

			&__title
				font-family: $ffb
				font-size: 100px
				line-height: 75px
				color: $green

			&__subtitle
				text-align: center
				font-family: $ffb
				font-size: 24px
				line-height: 24px
				margin-bottom: 16px

			&__description
				font-family: $ff
				font-size: 14px
				line-height: 130%
				margin-bottom: 24px
