.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	fill: currentColor;
}


#SVG_container{
	position: absolute;
	height: 0;
	width: 0;
}

#SVG_container svg{
	position: absolute;
	height: 0;
	width: 0;
}

.icon-1 {
	font-size:(50/10)*1rem;
	width:(54/50)*1em;
}
.icon-10 {
	font-size:(40/10)*1rem;
	width:(43/40)*1em;
}
.icon-11 {
	font-size:(40/10)*1rem;
	width:(44/40)*1em;
}
.icon-12 {
	font-size:(40/10)*1rem;
	width:(42/40)*1em;
}
.icon-13 {
	font-size:(40/10)*1rem;
	width:(44/40)*1em;
}
.icon-2 {
	font-size:(50/10)*1rem;
	width:(51/50)*1em;
}
.icon-3 {
	font-size:(50/10)*1rem;
	width:(55/50)*1em;
}
.icon-4 {
	font-size:(50/10)*1rem;
	width:(55/50)*1em;
}
.icon-43 {
	font-size:(24/10)*1rem;
	width:(24/24)*1em;
}
.icon-44 {
	font-size:(24/10)*1rem;
	width:(25/24)*1em;
}
.icon-49 {
	font-size:(24/10)*1rem;
	width:(24/24)*1em;
}
.icon-5 {
	font-size:(50/10)*1rem;
	width:(51/50)*1em;
}
.icon-50 {
	font-size:(24/10)*1rem;
	width:(24/24)*1em;
}
.icon-51 {
	font-size:(16/10)*1rem;
	width:(16/16)*1em;
}
.icon-52 {
	font-size:(14/10)*1rem;
	width:(14/14)*1em;
}
.icon-57 {
	font-size:(24/10)*1rem;
	width:(24/24)*1em;
}
.icon-6 {
	font-size:(50/10)*1rem;
	width:(53/50)*1em;
}
.icon-69 {
	font-size:(24/10)*1rem;
	width:(24/24)*1em;
}
.icon-7 {
	font-size:(40/10)*1rem;
	width:(44/40)*1em;
}
.icon-75 {
	font-size:(3/10)*1rem;
	width:(9/3)*1em;
}
.icon-8 {
	font-size:(40/10)*1rem;
	width:(44/40)*1em;
}
.icon-9 {
	font-size:(40/10)*1rem;
	width:(44/40)*1em;
}
.icon-add {
	font-size:(24/10)*1rem;
	width:(24/24)*1em;
}
.icon-arrow-bottom {
	font-size:(10/10)*1rem;
	width:(10/10)*1em;
}
.icon-arrow-right {
	font-size:(13/10)*1rem;
	width:(16/13)*1em;
}
.icon-arrows {
	font-size:(24/10)*1rem;
	width:(24/24)*1em;
}
.icon-back {
	font-size:(24/10)*1rem;
	width:(24/24)*1em;
}
.icon-basket {
	font-size:(23/10)*1rem;
	width:(23/23)*1em;
}
.icon-burger {
	font-size:(18/10)*1rem;
	width:(24/18)*1em;
}
.icon-cart {
	font-size:(20/10)*1rem;
	width:(21/20)*1em;
}
.icon-check {
	font-size:(7/10)*1rem;
	width:(9/7)*1em;
}
.icon-city-location {
	font-size:(18/10)*1rem;
	width:(12/18)*1em;
}
.icon-clock {
	font-size:(16/10)*1rem;
	width:(16/16)*1em;
}
.icon-close {
	font-size:(12/10)*1rem;
	width:(12/12)*1em;
}
.icon-cubes {
	font-size:(24/10)*1rem;
	width:(24/24)*1em;
}
.icon-delete {
	font-size:(24/10)*1rem;
	width:(24/24)*1em;
}
.icon-doc {
	font-size:(16/10)*1rem;
	width:(12/16)*1em;
}
.icon-down {
	font-size:(9/10)*1rem;
	width:(14/9)*1em;
}
.icon-down-1 {
	font-size:(5/10)*1rem;
	width:(10/5)*1em;
}
.icon-down-2 {
	font-size:(7/10)*1rem;
	width:(12/7)*1em;
}
.icon-email {
	font-size:(16/10)*1rem;
	width:(16/16)*1em;
}
.icon-en-flag {
	font-size:(24/10)*1rem;
	width:(24/24)*1em;
}
.icon-eye {
	font-size:(24/10)*1rem;
	width:(24/24)*1em;
}
.icon-facebook {
	font-size:(32/10)*1rem;
	width:(32/32)*1em;
}
.icon-favorite {
	font-size:(20/10)*1rem;
	width:(20/20)*1em;
}
.icon-fb {
	font-size:(30/10)*1rem;
	width:(30/30)*1em;
}
.icon-file {
	font-size:(16/10)*1rem;
	width:(12/16)*1em;
}
.icon-fill-like {
	font-size:(12/10)*1rem;
	width:(15/12)*1em;
}
.icon-google {
	font-size:(30/10)*1rem;
	width:(30/30)*1em;
}
.icon-home {
	font-size:(24/10)*1rem;
	width:(24/24)*1em;
}
.icon-info {
	font-size:(16/10)*1rem;
	width:(16/16)*1em;
}
.icon-instagram {
	font-size:(32/10)*1rem;
	width:(32/32)*1em;
}
.icon-left {
	font-size:(14/10)*1rem;
	width:(9/14)*1em;
}
.icon-like {
	font-size:(12/10)*1rem;
	width:(14/12)*1em;
}
.icon-lk {
	font-size:(24/10)*1rem;
	width:(24/24)*1em;
}
.icon-location {
	font-size:(18/10)*1rem;
	width:(12/18)*1em;
}
.icon-location-1 {
	font-size:(16/10)*1rem;
	width:(16/16)*1em;
}
.icon-login {
	font-size:(24/10)*1rem;
	width:(24/24)*1em;
}
.icon-maestro {
	font-size:(30/10)*1rem;
	width:(39/30)*1em;
}
.icon-mail {
	font-size:(12/10)*1rem;
	width:(22/12)*1em;
}
.icon-master-card {
	font-size:(30/10)*1rem;
	width:(40/30)*1em;
}
.icon-mir {
	font-size:(15/10)*1rem;
	width:(52/15)*1em;
}
.icon-mirror {
	font-size:(20/10)*1rem;
	width:(20/20)*1em;
}
.icon-phone {
	font-size:(16/10)*1rem;
	width:(16/16)*1em;
}
.icon-play {
	font-size:(35/10)*1rem;
	width:(36/35)*1em;
}
.icon-plus {
	font-size:(10/10)*1rem;
	width:(10/10)*1em;
}
.icon-profile {
	font-size:(20/10)*1rem;
	width:(20/20)*1em;
}
.icon-rating {
	font-size:(24/10)*1rem;
	width:(160/24)*1em;
}
.icon-restore {
	font-size:(16/10)*1rem;
	width:(17/16)*1em;
}
.icon-right {
	font-size:(14/10)*1rem;
	width:(9/14)*1em;
}
.icon-ru-flag {
	font-size:(24/10)*1rem;
	width:(24/24)*1em;
}
.icon-sale {
	font-size:(20/10)*1rem;
	width:(20/20)*1em;
}
.icon-save {
	font-size:(24/10)*1rem;
	width:(24/24)*1em;
}
.icon-search {
	font-size:(16/10)*1rem;
	width:(16/16)*1em;
}
.icon-star {
	font-size:(24/10)*1rem;
	width:(24/24)*1em;
}
.icon-top {
	font-size:(9/10)*1rem;
	width:(14/9)*1em;
}
.icon-top-2 {
	font-size:(7/10)*1rem;
	width:(12/7)*1em;
}
.icon-unload {
	font-size:(24/10)*1rem;
	width:(24/24)*1em;
}
.icon-visa {
	font-size:(15/10)*1rem;
	width:(48/15)*1em;
}
.icon-vk {
	font-size:(30/10)*1rem;
	width:(30/30)*1em;
}
.icon-vkontakte {
	font-size:(32/10)*1rem;
	width:(32/32)*1em;
}
.icon-webest {
	font-size:(16/10)*1rem;
	width:(80/16)*1em;
}
.icon-write {
	font-size:(24/10)*1rem;
	width:(24/24)*1em;
}
