.slider-pagination
	display: flex
	align-items: baseline

	&--intro
		position: absolute
		z-index: 2
		left: 0
		right: 0
		justify-content: center

		.swiper-pagination-bullet
			background: rgba(255, 255, 255, 0.3)

			&-active
				background: #fff

	&--black
		bottom: 10px

		.swiper-pagination-bullet
			background: $color-text-1

	&--little

		.swiper-pagination-bullet
			width: 4px
			height: 4px
			margin: 0 2px !important

			&:last-child
				margin-right: 0

			&-active
				width: 12px



.swiper-pagination-bullet
	width: 7px
	height: 7px
	background: $gray
	opacity: 1
	border-radius: 10px
	margin-right: 13px
	transition: width .3s, background .3s

	&:last-child
		margin-right: 0

	&-active
		width: 21px

	+max-screen(767px)
		width: 4px
		height: 4px

		&-active
			width: 12px




.slider-intro

	.slider-pagination
		bottom: 25px

	+max-screen(767px)

		.slider-pagination
			bottom: 8px



.swiper-slide
	img
		height: 100%
		width: 100%
		object-fit: cover
