.product-cards
	display: flex
	flex-wrap: wrap
	margin: -10px -10px 35px

	.product-card
		width: calc(100% / 4 - 20px)
		margin: 10px

	+max-screen(1320px)
		.product-card
			width: calc(100% / 4 - 10px)
			margin: 5px

	+max-screen(767px)
		.product-card
			width: calc(100% / 2 - 8px)
			margin: 4px


	
.bx_catalog_item_container
	height: 100%


	
.product-card
	display: flex
	flex-direction: column
	width: 100%
	max-width: 310px

	&__head
		position: relative
		z-index: 2

	&__images
		position: relative
		display: block

		&:hover

			.product-card__img
				opacity: 0

				&--hover
					opacity: 1

	&__img
		width: 100%
		height: 100%
		object-fit: contain
		border-radius: 10px
		transition: opacity ease .3s

		&--hover
			position: absolute
			top: 0
			left: 0
			opacity: 0

	&__link
		display: block

		&:hover
			color: $color-text-1

		.product-card__body
			height: auto

	&__body
		display: grid
		grid-gap: 8px
		height: 100%
		line-height: 100%
		margin-top: 8px

		&--bottom
			align-items: end
			grid-template-columns: 1fr 1.15fr

		&-right
			align-self: flex-end

			.product-card__subtitle
				color: $red

	&__title
		display: flex

	&__subtitle
		color: $green
		font-family: $ffs
		font-size: 12px
		margin-bottom: 8px

	&__price
		display: flex
		align-items: center
		justify-content: space-between

	+max-screen(1240px)

		&__body

			&--bottom
				grid-template-columns: 1fr

		&__price
			justify-content: flex-start

			.price
				margin-right: 16px

	+max-screen(768px)

		&__title
			max-width: 140px
			word-break: break-all

		&__price
			justify-content: space-between

			.price
				margin-right: 0



.marker
	padding: 5px 4px
	font-family: $ffb
	background: $gray
	color: $white
	font-size: 10px
	line-height: 100%
	border-radius: 5px
	position: absolute
	top: 6px
	left: 4px
	cursor: default

	&--beige
		background: $color-bg-3
		color: $color-text-1



.price
	display: flex
	align-items: center

	&__new
		font-family: $ffb
		font-size: 14px
		line-height: 100%
		margin-right: 8px

		&:last-child
			margin-right: 0

	&__old
		font-size: 10px
		line-height: 100%
		text-decoration-line: line-through



.sale
	padding: 4px 6px
	background: $red
	font-size: 10px
	line-height: 100%
	color: $white
	border-radius: 10px



.product-card-mini
	display: grid
	grid-template-columns: 60px 1fr
	grid-gap: 16px
	align-items: center
	padding: 16px 0
	border-top: 1px solid #EAEFF2
	border-bottom: 1px solid #EAEFF2
	width: max-content
	margin: auto auto 20px

	&__img
		width: 60px
		height: 60px
		object-fit: contain
		border-radius: 10px

	&__name
		max-width: 210px
		width: 100%
