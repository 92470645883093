body
	font-family: $ff
	line-height: 1.3
	font-size: 14px
	color: #000000
	overflow-x: hidden

	*
		box-sizing: border-box
		font-family: $ff

a
	color: $color-text-1
	text-decoration: none
	transition: color ease .3s

	&:hover
		color: $green

p
	margin: 0 0 20px

	&:last-child
		margin: 0

ul
	margin: 0
	padding: 0
	list-style-type: none

.body--fix
	position: fixed
	left: 0
	right: 0


.section-header
	margin-bottom: 30px

	&__title
		font-family: $ffb
		font-size: 24px
		line-height: 24px
