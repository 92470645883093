// ------------- FONTS NAME -------------
$ff: "Gilroy-Medium"
$ffi: "Gilroy-Medium-Italic"
$ffs: "Gilroy-SemiBold"
$ffsi: "Gilroy-SemiBold-Italic"
$ffb: "Gilroy-Bold"
$ffbi: "Gilroy-Bold-Italic"

// ------------- CONTAINER -------------
$container-desktop: 1300px
$container-laptop: 1220px
$container-tablet--h: 964px
$container-tablet--v: 708px
$container-mobile: 320px


// ------------- COLORS - BASE -------------
$green: #56AF40


// ------------- COLORS - TEXT -------------
$color-text-1: #000000
$color-text-2: #8B928C


// ------------- COLORS - BORDER -------------
$color-br-1: #62B0FF


// ------------- COLORS - BACKGROUND -------------
$color-bg-1: #F4F4F4
$color-bg-2: #E7F1E5
$color-bg-3: #BBDFB3
$color-bg-3: #FFF1E1

// ------------- COMMON -------------
$red: #EB5757
$gray: #BACAD5

$tr: transparent
$white: #FFFFFF
