.custom-select
	max-width: 100%
	position: relative

	&--open

		.custom-select

			&__arrow

				.icon
					transform: rotate(180deg)

			&__body
				box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1)
				transform: translateY(0px)
				opacity: 1
				pointer-events: auto

	&__header
		background: $color-bg-1
		cursor: pointer
		outline: none
		display: flex
		align-items: center
		justify-content: space-between
		padding: 12px 16px
		min-height: 40px
		width: 100%
		transition: background ease .3s, border ease .3s
		border: 1px solid $tr
		border-radius: 10px
		font-size: 12px
		line-height: 130%

		&:hover,
		&.active
			border: 1px solid $green

	&__body
		position: absolute
		top: 43px
		left: 0
		right: 0
		background: #fff
		transition: box-shadow ease .3s, transform ease .3s, opacity ease .3s
		z-index: 10
		max-height: 260px
		transform: translateY(15px)
		border-radius: 10px
		opacity: 0
		pointer-events: none
		overflow: hidden

		&-list
			max-height: 260px

			.simplebar-track
				right: 5px

	&__selected
		line-height: 1
		font-size: 14px
		color: #000000
		text-align: left
		white-space: nowrap
		overflow: hidden
		text-overflow: ellipsis

	&__arrow
		color: #000000
		margin-left: 27px
		display: flex
		align-items: center
		justify-content: flex-end

	.icon
		color: rgba(0, 0, 0, 0.5)
		font-size: 8px !important
		transition: transform ease .3s

	&__label
		padding: 16px 28px 14px
		font-size: 14px
		line-height: 16px
		color: #000000
		background: #fff
		transition: background ease .3s
		cursor: pointer
		display: block
		border-radius: 10px
		user-select: none

		&:hover
			background: #E7F1E5

	[type="checkbox"],
	[type="radio"]
		display: none

		&:checked
			~
				.custom-select__label
					background: #E7F1E5
