.tooltip
	opacity: 0
	position: absolute
	top: 20px
	left: -20px
	padding: 15px 20px
	background: $white
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25)
	border-radius: 10px
	color: $color-text-1
	transition: opacity ease .3s
	pointer-events: none
	width: max-content
	z-index: 10

	&__title
		margin-bottom: 15px

	&__text
		font-family: $ffs
		font-size: 12px
		line-height: 120%

	&__item
		display: flex
		align-items: center
		margin-bottom: 15px

		&:last-child
			margin-bottom: 0

	.icon
		font-size: 13px
		margin-right: 10px
		color: $gray

	img
		margin-right: 10px

	&--right
		top: 13px
		left: 28px

	+max-screen(500px)
		min-width: 160px
		width: 100%
