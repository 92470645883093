@mixin bgi()
	background-position: center center
	background-repeat: no-repeat
	background-size: cover

@mixin overlay($color) 
	position: relative
	z-index: 0

	&:before 
		content: ""
		z-index: -1
		position: absolute
		top: 0
		bottom: 0
		left: 50%
		width: 100vw
		height: 100%
		margin: 0 0 0 (-50vw)
		background: $color
	
@mixin reset_btn()
	cursor: pointer
	outline: none
	background: none
	border: none
	box-shadow: none
