.widget_list
	padding-left: 0px
	display: grid
	grid-template-columns: 1fr 1fr



.ui
	margin-bottom: 50px

	&-section
		padding: 50px

		td
			padding: 10px

		.input-group
			max-width: 480px
			margin-bottom: 20px

	&-header
		padding: 35px 50px
		background: #F4F7FB
		color:  #5A6872
		font-size: 54px

	&-title
		font-size: 24px
		padding-bottom: 18px



.ui-colors
	display: flex
	align-items: flex-start
	margin-bottom: 45px
	flex-wrap: wrap



.ui-color
	display: flex
	flex-direction: column
	justify-content: flex-start
	align-items: flex-start
	margin-right: 22px

	&__bg
		width: 80px
		height: 50px
		margin-bottom: 10px
		border: 1px solid #EFF2F5

	&__name
		color: #5A6872
		margin-bottom: 10px

	&__code
		color: #000
		text-decoration: underline
