.form-search-wrapper
	position: relative

.form-search
	display: flex
	align-items: stretch
	overflow: hidden
	border-radius: 10px
	background: #F4F4F4
	width: 100%
	max-width: 600px
	border: 1px solid $tr
	transition: border ease .3s

	&__input
		padding: 13px 20px
		border: 0
		border-radius: 0
		background-color: $tr
		font-size: 12px
		line-height: 14px
		min-height: 40px

		&:hover:not(:disabled),
		&:focus:not(:disabled)
			border: none

	&__submit.btn
		width: max-content
		padding: 10px
		border: none
		background-color: $tr

		.icon
			color: $green
			font-size: 20px

		&:hover
			background-color: transparent

	&.active
		border: 1px solid $green



.form-search-result
	display: none
	position: absolute
	top: 32px
	left: 0
	padding: 10px
	background: #FFFFFF
	border-radius: 2px
	box-shadow: 0px 5px 9px rgba(0, 0, 0, 0.3)
	border-radius: 2px
	width: 350px

	&__list
		max-height: 255px

	&__item
		display: flex
		text-decoration: none
		color: $color-text-1
		margin-right: 6px

		&-img
			margin: 5px 12px 5px 0

		&-title
			display: flex
			align-items: center
			width: 100%
			font-size: 12px
			line-height: 14px
			border-bottom: 1px solid #CDD8E2

		&:last-child
			.form-search-result__item-title
				border-bottom: none
