.catalog-wrapper
	display: flex
	flex-wrap: wrap
	margin: -10px -10px 35px

	+max-screen(1200px)
		margin: -6px -6px 35px

.section-catalog
	display: flex
	padding: 25px 20px 11px
	background: #F4F4F4
	border-radius: 10px
	margin: 10px
	width: 100%
	grid-gap: 30px
	transition: background ease .3s

	&__cover
		display: block
		margin-right: 18px

		&:last-child
			margin-right: 0

	&__img
		width: 100%
		object-fit: contain
		border-radius: 10px
		margin-bottom: 11px
		min-width: 270px

	&__title
		font-family: $ffs
		font-size: 20px
		line-height: 24px

	&__list
		display: grid
		grid-template-columns: repeat(3, 300px)
		grid-row-gap: 1px
		grid-column-gap: 30px
		height: max-content

		&-wrapper
			width: 100%

	&__item
		display: flex
		align-items: center
		transition: background ease .3s, color ease .3s
		padding: 0 12px

		span
			display: flex
			align-items: center
			height: 100%
			width: 100%
			padding: 12px 0
			border-top: 1px solid #E3E7EB
			transform: translateY(-1px)

		&:hover
			background: $green
			color: $white

	&__btn
		display: none

	&--two-thirds
		width: calc(100% / 1.33333 - 20px)

		.section-catalog__list
			grid-template-columns: repeat(2, 1fr)

	&--fourth
		width: calc(100% / 4 - 20px)

	&--middle
		width: calc(100% / 2 - 20px)

		.section-catalog__list
			grid-template-columns: 1fr

	+max-screen(1300px)

		&__img
			min-width: 250px

	+max-screen(1200px)
		margin: 6px

		&__img
			min-width: 200px

		&__list
			display: grid
			grid-template-columns: repeat(3, 220px)

		&__item
			max-width: 220px

		&--two-thirds
			width: calc(100% / 1.33333 - 12px)

		&--fourth
			width: calc(100% / 4 - 12px)

		&--middle
			width: calc(100% / 2 - 12px)

	+max-screen(1023px)
		flex-direction: column

		&__img
			max-width: 80px
			min-width: 100px
			margin: 0 12px 0 0

		&__cover
			display: flex
			align-items: center
			margin: 0 0 12px 0

		&__list
			grid-template-columns: repeat(3, 1fr)
			grid-column-gap: 10px

		&--two-thirds,
		&--fourth,
		&--middle
			width: 100%

			.section-catalog__list
				grid-template-columns: repeat(3, 1fr)
				grid-column-gap: 10px

	+max-screen(605px)
		padding: 12px
		cursor: pointer
		grid-gap: 15px

		&__link
			pointer-events: none

		&__cover
			margin: 0

		&__img
			min-width: 80px

		&__list
			margin: 0 -20px
			grid-template-columns: 1fr

			&-wrapper
				display: none

		&__item
			max-width: 100%
			padding: 0 20px

		&__btn
			display: flex
			justify-content: center
			align-items: center
			height: 24px
			max-width: 24px
			min-width: 24px
			width: 100%
			border-radius: 24px
			background: $gray
			margin-left: auto
			margin-right: 0
			transition: background ease .3s

			.icon
				font-size: 6px
				color: $white
				transition: transform ease .3s

		&.active
			background: #E7F1E5

			.section-catalog

				&__btn
					background: $green

					.icon
						transform: rotate(180deg)

		&--two-thirds,
		&--fourth,
		&--middle
			width: 100%

			.section-catalog__list
				grid-template-columns: 1fr

		&--fourth

			.section-catalog__btn
				display: none

			.section-catalog__link
				pointer-events: all


.search-header
	margin-bottom: 20px