.breadcrumb
	position: relative
	margin-top: 20px
	margin-bottom: 15px
	z-index: 1

	&__list
		position: relative
		width: 100%
		display: inline-flex
		align-items: center
		overflow-y: hidden
		overflow-x: auto
		padding: 0
		margin: 0
		list-style-type: none
		white-space: nowrap

		&::-webkit-scrollbar
			display: none

	&__item
		font-size: 12px
		line-height: 18px
		color: $gray
		margin-right: 8px

		&:last-child
			margin-right: 0

	a
		color: inherit
		text-decoration: none

		&:hover
			text-decoration: underline
