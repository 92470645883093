.nav
	display: flex
	padding: 0
	list-style-type: none
	margin: 0 -8px

	&__item
		padding: 0 8px

		&:last-child
			padding-left: 22px


		&--link
			display: flex
			gap: 5px

			.nav__link
				color: $green

	&__link
		color: $color-text-1
		display: flex
		align-items: center
		font-size: 14px
		line-height: 130%
		text-decoration: none
		transition: color ease .3s
		position: relative

		&:hover
			color: $green

		&-counter
			position: absolute
			top: -10px
			right: 13px
			padding: 5px 10px
			background-color: $green
			font-size: 12px
			line-height: 12px
			color: #fff
			border-radius: 10px

			&:empty
				padding: 0

		img
			margin-right: 10px

	&__title
		font-family: $ffs



	&--user-menu
		margin: 0

		.nav

			&__item
				padding: 0
				margin-left: 20px

				&:first-child
					margin-left: 0

				&:last-child
					margin-left: 20px

			&__link
				flex-direction: column
				align-items: center
				text-align: center
				width: 90px

				span
					font-family: $ffs
					font-size: 12px
					line-height: 11px

				img
					width: 20px
					height: 20px
					margin: 0 0 10px

	&--header
		margin: 0
		justify-content: space-between

		.nav

			&__item
				width: 100%
				min-width: 64px
				padding: 0
				transition: background ease .3s

				&:first-child
					border-radius: 15px 0px 0px 0px

				&:last-child
					border-radius: 0px 15px 0px 0px

				&:hover
					background: $green

					.nav__name,
					.icon
						color: $white

					.icon-favorite,
					.icon-lk
						stroke: $white


			&__link
				flex-direction: column
				align-items: center
				text-align: center
				padding: 10px 0 8px

				.icon
					font-size: 24px
					margin-bottom: 2px
					transition: color ease .3s


				.icon-favorite,
				.icon-lk
					stroke: #000000
					fill: transparent

				img
					width: 24px
					height: 24px
					margin: 0 0 2px

			&__name
				font-family: $ffs
				font-size: 10px
				line-height: 13px
				transition: color ease .3s
