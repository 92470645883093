.page--catalog-list

	.back-btn
		margin-top: 0


.section-catalog-header
	display: grid
	grid-template-columns: 1fr max-content
	grid-gap: 20px
	margin-bottom: 20px

	.tabs
		flex-wrap: wrap
		margin: -4px

		&__item
			margin: 4px


.section-footer
	margin-top: 45px

	&__btn
		width: max-content

	&__text
		margin-bottom: 16px
