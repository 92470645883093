.section-blog-main
	width: 100%
	height: 400px
	border-radius: 10px
	background-size: cover
	display: flex
	justify-content: center
	align-items: flex-end
	margin-bottom: 20px
	position: relative

	&:hover
		color: inherit

	&__title,
	&__subtitle
		width: 100%
		max-width: 450px
		text-align: center

	&__subtitle
		display: flex
		align-items: center
		justify-content: center

	&__title
		font-family: $ffs
		font-size: 20px
		line-height: 24px
		color: $white
		margin-bottom: 16px

	&__tag
		font-size: 12px
		line-height: 18px
		padding: 3px 8px
		background: $white
		border-radius: 10px

	&__text
		font-family: $ffb
		color: $white
		font-size: 12px
		line-height: 100%
		margin-left: 9px

	&__content
		z-index: 1
		margin-bottom: 45px

	&--front

		&::before
			content: ""
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			border-radius: 10px
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.26), rgba(0, 0, 0, 0.26))

	+max-screen(1024px)
		height: 300px

	+max-screen(540px)
		height: 200px



.section-blog
	display: grid
	grid-template-columns: repeat(4, 1fr)
	grid-gap: 20px
	margin: 45px 0

	+max-screen(1024px)
		grid-template-columns: repeat(3, 1fr)

	+max-screen(767px)
		grid-template-columns: 1fr




.card-news
	display: flex
	flex-direction: column
	justify-content: space-between
	cursor: pointer
	height: 100%

	&__img
		width: 100%
		height: 190px !important
		object-fit: cover
		margin-bottom: 12px
		border-radius: 10px

	&__title,
	&__description
		line-height: 100%

	&__description
		font-family: $ffb
		font-size: 14px
		color: $gray
		margin-top: 12px



.section-blog-subtitle
	display: grid
	grid-template-columns: repeat(3, max-content)
	grid-gap: 20px
	justify-content: center
	align-items: center
	margin-bottom: 20px

	&__tag
		font-size: 12px
		line-height: 18px
		padding: 3px 8px
		background: $color-bg-1
		color: $color-text-1
		width: max-content
		border-radius: 10px

	&__text
		font-family: $ffb
		color: $gray
		font-size: 12px
		line-height: 100%

	+max-screen(540px)
		grid-template-columns: repeat(2, max-content)
		justify-content: flex-start

		&__signature
			grid-column: 1/3



.blog-container
	width: 100%
	max-width: 640px
	margin: 0 auto 90px



.title-block
	margin-top: 40px

.therm-box

	.word-box
		display: grid
		grid-gap: 10px
		grid-template-columns: repeat(5, 1fr)

		.item
			float: none
			width: 100%
			padding-bottom: 0

			a
				text-decoration: underline
				color: $green

				&:hover
					text-decoration: none

			.word.bold
				font-family: $ffs

		+max-screen(1023px)
			grid-template-columns: repeat(4, 1fr)
			
		+max-screen(767px)
			grid-template-columns: repeat(2, 1fr)