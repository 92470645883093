.input-rating
	display: flex
	justify-content: center
	flex-direction: row-reverse
	margin-bottom: 15px

	input
		display: none

	&__icon:not(:first-of-type)
		padding-right: 7px

	&__icon:not(:last-of-type)
		padding-left: 7px

	&__icon .icon
		font-size: 21px
		cursor: pointer
		color: $gray

	&__icon .icon-fill
		display: none
		color: #F2994A

	&__icon:hover

		.icon-empty
			display: none

		.icon-fill
			display: inline-flex

		~
			.input-rating__icon

				.icon-fill
					display: inline-flex

				.icon-empty
					display: none

	&__icon--active

		.icon-empty
			display: none

		.icon-fill
			display: inline-flex

		~
			.input-rating__icon

				.icon-empty
					display: none

				.icon-fill
					display: inline-flex
