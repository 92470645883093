.btn
	+reset_btn()
	font-family: $ffs
	padding: 9px 19px
	font-size: 14px
	line-height: 100%
	width: 100%
	display: flex
	align-items: center
	justify-content: center
	text-align: center
	transition: all ease .3s

	&__img
		margin-left: 10px

	&:disabled
		opacity: .5

	&--base,
	&--big,
	&--counter
		&:hover:not(:disabled),
		&:active
			background-color: #71C95C
			color: $white

	&--base
		color: $white
		border-radius: 5px
		background-color: $green

	&--big
		color: $white
		font-family: $ffb
		background-color: $green
		padding: 13px 20px
		border-radius: 10px

	&--light
		background-color: #E7F1E5
		color: $color-text-1
		font-family: $ffb
		padding: 13px 20px
		border-radius: 10px

		.icon
			font-size: 20px
			color: #BBDFB3

		&:hover:not(:disabled),
		&:active
			color: $green

	&--counter
		background-color: $green
		width: max-content
		color: $white
		border-radius: 5px
		font-size: 21px
		padding: 6px 11px

	&--small
		color: $white
		width: max-content
		font-size: 20px
		background-color: #BACAD5
		padding: 2px 7px
		border-radius: 7px

		&:hover:not(:disabled),
		&:active
			background-color: #ABB9C2

	&--icon
		color: $white
		padding: 8px
		border-radius: 10px

		.icon
			width: 24px
			height: 24px

		&:hover:not(:disabled),
		&:active
			background-color: $green

	&--upward
		color: $white
		width: max-content
		padding: 17px 14px
		background-color: $white
		border-radius: 10px
		box-shadow: 0px 4px 14px rgba(53, 151, 63, 0.35)

		.icon
			font-size: 7px
			color: $color-text-1
			transition: color ease .3s

		&:hover:not(:disabled),
		&:active
			background-color: $green

			.icon
				color: $white

	&--red
		color: $white
		border-radius: 5px
		padding: 15px 10px
		background-color: $red

		&:hover:not(:disabled),
		&:active
			opacity: .7

	&--transparent
		padding: 4px
		color: $green

		&:hover:not(:disabled),
		&:active
			text-decoration: underline

	&--input
		position: absolute
		top: 0
		right: 0
		width: max-content
		padding: 8px

		.icon
			font-size: 24px
			color: #200E32
			transition: color ease .3s

		&.active

			.icon
				color: $green



.counter
	display: flex

	&__value
		display: flex
		align-items: center
		justify-content: center
		font-family: $ffb
		padding: 8px 0
		width: 100%
		background: $color-bg-1
		border-radius: 5px
		margin: 0 8px

	&--small
		.counter__value
			padding: 4px 0
			background: $tr
			margin: 0 12px



.link
	font-family: $ffb
	color: $green
	text-decoration: underline
	font-size: 14px
	line-height: 130%
	cursor: pointer
	transition: text-decoration ease .3s

	&:hover
		text-decoration: none

	&--black
		font-family: $ff
		color: $color-text-1
		line-height: 100%

		&:hover
			color: $color-text-1

	&--small
		font-family: $ff
		font-size: 12px
		line-height: 100%


.info-title
	display: flex
	align-items: center
	font-family: $ffs
	font-size: 16px
	line-height: 18px


.info
	display: flex
	align-items: center
	position: relative
	cursor: pointer
	margin-left: 10px

	.icon
		font-size: 16px
		color: #5A6872

	&:hover

		.tooltip
			opacity: 1
			pointer-events: all




.switch
	position: relative
	display: inline-block
	width: 44px
	height: 24px

	&__slider
		position: absolute
		cursor: pointer
		top: 0
		left: 0
		right: 0
		bottom: 0
		background-color: #F2F2F2
		transition: .4s
		border-radius: 50px

		&:before
			position: absolute
			content: ""
			height: 16px
			width: 16px
			left: 4px
			bottom: 50%
			transform: translateY(50%)
			background-color: #E0E0E0
			transition: .4s
			border-radius: 50%

	input
		display: none

		&:checked + .switch__slider
			background-color: $green

		&:checked + .switch__slider:before
			transform: translate(16px, 50%)
			background-color: $white



.like-btn
	width: 24px
	height: 24px
	border-radius: 50%
	background-color: $white
	position: absolute
	top: 4px
	right: 4px
	z-index: 2
	padding: 0
	transition: transform ease .1s

	&__icon
		position: absolute
		top: 50%
		left: 50%
		transform: translate(-50%, -50%)
		width: 12px
		height: 10px
		transition: opacity ease .1s, color ease .3s

		&:hover
			color: $red

		&--filled
			opacity: 0

	&--active

		.like-btn
			transform: scale(0.95)

			&__icon

				&--outline
					opacity: 0

				&--filled
					opacity: 1
					color: $red



.back-btn
	display: inline-flex
	align-items: center
	margin: 20px 0

	&__wrap
		display: flex
		justify-content: center
		align-items: center
		background: $gray
		border-radius: 24px
		min-width: 24px
		max-width: 24px
		height: 24px
		margin-right: 12px

	.icon
		color: $white
		font-size: 10px



.button-save
	align-items: flex-start
	justify-content: flex-start
	padding: 16px
	background: $color-bg-1
	border-radius: 10px
	max-width: 320px
	text-align: start
	margin-bottom: 12px

	&:last-child
		margin-bottom: 0

	&__title
		line-height: 17px

	&__icon
		display: flex
		padding: 4px
		background: $white
		border-radius: 10px
		margin-right: 12px
		transition: all ease .3s

		&:last-child
			margin-right: 0

		.icon
			font-size: 24px
			color: $green
			transition: all ease .3s

	&:hover
		.button-save__icon
			background: $green

		.icon
			color: $white

	&--green
		background: #E7F1E5


.filter-btn
	padding: 7px 13px
	font-family: $ffb
	font-size: 12px
	line-height: 18px
	text-transform: uppercase
	color: $white
	border-radius: 10px
	background: #000
	position: relative
	height: max-content

	&__notification
		position: absolute
		top: -4px
		right: 0
		width: 12px
		height: 12px
		border-radius: 12px
		background: $green
