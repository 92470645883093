.page--product

	+max-screen(900px)

		.accordeon
			max-width: 100%

		.accordeon-head
			padding: 20px 0



.section-product
	display: flex
	justify-content: space-between
	margin-bottom: 45px

	&__wrapper
		display: flex

	&__card
		margin-right: 20px

	&__head
		position: relative
		z-index: 2

		.marker
			top: 20px
			left: 20px
			padding: 7px 11px
			color: $color-text-1

		.like-btn
			width: 28px
			height: 28px
			top: 20px
			right: 20px

	&__slider
		min-width: 530px
		max-width: 530px
		width: 100%

		.slider-pagination
			bottom: 25px

	&__info
		width: 100%
		max-width: 310px
		padding-bottom: 25px
		border-bottom: 1px solid #EAEFF2

	&__column
		margin-right: 130px

	&__title
		font-family: $ffs
		font-size: 20px
		line-height: 24px
		margin-bottom: 16px

	&__subtitle
		margin-bottom: 8px

	&__text

		&-value
			color: #AAAAAA
			margin-left: 16px

	&__price
		display: flex
		align-items: center
		margin-bottom: 16px

		.price
			margin-right: 20px

			&__new
				font-size: 20px

			&__old
				font-size: 14px

		.sale
			font-size: 12px

	&__btn
		position: relative
		margin-bottom: 25px

		&-count
			font-family: $ffb
			line-height: 100%
			background: $white
			padding: 9px
			color: $color-text-1
			box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25)
			border-radius: 10px
			position: absolute
			top: -8px
			right: 17px

	+max-screen(1280px)

		&__column
			margin-right: 0

	+max-screen(1120px)
		flex-direction: column

		&__column
			margin-top: 45px

		&__slider
			max-width: 350px
			min-width: 350px

			.slider-pagination
				bottom: 10px

	+max-screen(767px)
		&__info
			max-width: 100%

		&__wrapper
			flex-direction: column

		&__card
			margin-right: 0

		&__head

		.marker,
		.like-btn
			top: 12px

		&__slider
			min-width: 100%




.section-reviews
	width: 100%
	max-width: 530px
	margin-bottom: 65px

	&__header
		display: flex
		align-items: center
		justify-content: space-between
		margin-bottom: 16px

		.btn
			width: max-content

	&__cards
		margin-bottom: 25px

	&__title

		&-value
			font-size: 16px
			line-height: 20px
			margin-left: 8px

	+max-screen(900px)
		max-width: 100%


.review-card
	background: $color-bg-1
	padding: 20px 12px
	border-radius: 10px
	margin-bottom: 12px

	&:last-child
		margin-bottom: 0

	.btn
		width: max-content

	&__title
		margin-bottom: 10px

		&-text,
		&-value
			font-family: $ffb
			font-size: 16px

		&-value
			margin-left: 8px

	&__description
		overflow: hidden
		position: relative
		max-height: 55px
		margin-bottom: 10px
		transition: max-height 0.3s ease-out

		&::after
			content: ""
			text-align: right
			position: absolute
			bottom: 0
			right: 0
			left: 0
			max-height: 1.2em
			height: 100%
			background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #F4F4F4 100%)
			pointer-events: none
			transition: max-height 0.2s ease-out

		&.active
			max-height: 500px
			transition: max-height 0.2s ease-in

			&::after
				max-height: 0
				transition: max-height 0.2s ease-in


.rating
	display: flex
	align-items: center
	margin-bottom: 20px

	&__value
		font-family: $ffb
		font-size: 18px
		margin-left: 15px


.rating-total
	background: #EBBE97
	width: 160px
	height: 24px

	&__background
		background: #F2994A
		position: relative
		height: 24px

		img
			position: absolute



.section-check
	display: flex
	align-items: center
	margin-bottom: 34px

	.icon
		font-size: 24px
		color: $green

	&__description
		font-family: $ffb
		margin-left: 8px

	&--error
		.icon
			color: $red
