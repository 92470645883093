.page--lk,
.page--lk-order

	.tabs-wrapper
		margin-bottom: 30px

	.section-check
		margin-bottom: 22px

		&__description
			color: $color-text-2

	.table
		width: 100%
		max-width: 290px

		.text-gray-second
			width: 100%
			max-width: 80px



.page--lk-order

	.btn
		width: 100%
		max-width: 290px


.section-lk
	margin-bottom: 45px


.products
	display: flex

	&__item,
	&__item-last
		width: 57px
		height: 57px
		border-radius: 10px

	&__item
		margin-right: 10px

		&:last-child
			margin-right: 0

		img
			width: 100%
			height: 100%
			object-fit: cover

		&-last
			display: flex
			justify-content: center
			align-items: center
			background: #BBDFB3


.address-list
	width: 100%
	max-width: 450px

	&__title
		margin-bottom: 15px

	&__item.btn
		&:hover

			.icon
				color: $green

	&__item
		display: flex
		justify-content: space-between
		padding: 22px 0
		border-bottom: 1px solid #EAEFF2

		&:first-child
			padding-top: 0

		&--tr
			border-bottom: none

	&__wrapper
		display: flex
		align-items: center

	&__text
		font-size: 16px

	&__icon
		margin-right: 8px

	&__btn
		width: max-content

	.icon
		font-size: 24px
		color: #BBDFB3
		transition: color ease .3s

	.form-default
		width: 100%
		max-width: 290px

		.input-group
			margin-bottom: 16px



.product-items
	margin-bottom: 30px



.product-item
	display: flex
	width: 100%
	max-width: 450px
	padding: 20px 0
	border-bottom: 1px solid #EAEFF2

	&__title
		margin-bottom: 12px

	&__img
		width: 60px
		height: 60px
		object-fit: cover
		border-radius: 10px
		margin-right: 12px

	&__info
		width: 100%

	&__row
		display: flex
		align-items: center
		justify-content: space-between

		.btn
			width: max-content

		.like-btn
			position: relative
			width: 24px
			top: 0
			left: 0
			right: 0

	.price
		margin-bottom: 16px

		&__old
			font-size: 14px
