.page--distributor
	.accordeon-body
		display: block
		width: 100%
		max-width: 480px

	.card-wrapper
		height: max-content


.page--company,
.page--shops

	.tabs-wrapper
		margin-bottom: 30px

	.grid-container__cards
		margin-top: 45px

		+max-screen(640px)
			margin-top: 20px

	.card-brand__title
		min-width: 100%


.page--shops

	.card-wrapper

		&__title,
		&__content
			margin-left: auto
			margin-right: auto
			width: 100%
			max-width: 250px

		&--green
			text-align: center
			padding: 28px
			margin-top: 45px

	+max-screen(1023px)
		.grid-container
			&__description
				width: 100%
				max-width: 640px

			&__title
				margin-bottom: 0

	+max-screen(630px)
		.card-wrapper
			&__content
				grid-template-columns: repeat(2, 1fr)


.page--vacancies

	.section-info
		text-align: center



.video-link
	display: block
	margin-bottom: 30px

	+max-screen(570px)
		img
			width: 100%
			height: 100%
			object-fit: contain



.section-company
	display: flex
	margin-bottom: 45px

	&__content
		margin-left: 130px

	&__title,
	&__subtitle
		width: 100%
		max-width: 360px

	&__title
		margin-bottom: 8px

	&__subtitle
		color: $color-text-2

	+max-screen(1024px)
		width: 100%
		max-width: 640px
		flex-direction: column

		&__content
			margin: 45px 0 0



.card-brand
	display: flex
	flex-direction: column
	justify-content: flex-start
	align-items: center
	padding: 16px
	border: 1px solid $color-bg-1
	border-radius: 10px
	height: 100%
	cursor: pointer
	transition: border ease .3s

	&__img
		max-height: 100px
		object-fit: contain
		margin-bottom: 16px

	&__title
		width: 100%
		min-width: 280px
		text-align: center

		.card-brand__title
			color: $color-text-1

	&--row
		flex-direction: row
		grid-gap: 12px

		.card-brand

			&__img
				width: 220px
				min-width: 220px
				margin: 0

			&__text
				display: grid

				.card-brand__title
					text-align: start
					margin-bottom: 12px

	+max-screen(1350px)
		&__title
			min-width: 100%

	+max-screen(767px)
		&--row
			flex-direction: column


	
.section-cities
	display: grid
	grid-template-columns: repeat(3, 1fr)
	grid-column-gap: 20px
	margin-bottom: 30px

	&:last-child
		margin-bottom: 0

	a
		padding: 8px 0

	+max-screen(425px)
		grid-template-columns: repeat(2, 1fr)
