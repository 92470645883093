.input-group

	&__label
		margin-bottom: 8px

	&__input
		position: relative

	&__error
		color: #FC6464
		font-size: 12px

	&--error

		.input
			border: 1px solid #E53935

		.input-group__error
			font-size: 11px
			line-height: 130%
			padding-top: 4px



// .input
// 	&::-webkit-input-placeholder
// 		color: rgba(140, 140, 140, 0.5)
// 		transition: color ease .3s

// 	&:-ms-input-placeholder
// 		color: rgba(140, 140, 140, 0.5)
// 		transition: color ease .3s

// 	&::-moz-placeholder
// 		color: rgba(140, 140, 140, 0.5)
// 		transition: color ease .3s

// 	&:-moz-placeholder
// 		color: rgba(140, 140, 140, 0.5)
// 		transition: color ease .3s



input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
	-webkit-box-shadow: 0 0 0 32px $color-bg-1 inset !important
	color: #000000



.input
	background: $color-bg-1
	color: $color-text-1
	padding: 12px 15px
	min-height: 40px
	border: 1px solid $tr
	border-radius: 10px
	box-shadow: none
	outline: none
	font-size: 12px
	line-height: 130%
	width: 100%
	transition: background ease .3s, border ease .3s

	&::-webkit-input-placeholder,
	&:-ms-input-placeholder,
	&::-moz-placeholder,
	&:-moz-placeholder
		color: $color-text-2

	&:hover:not(:disabled)
		border: 1px solid $green
		color: $color-text-1

		&::-webkit-input-placeholder,
		&:-ms-input-placeholder,
		&::-moz-placeholder,
		&:-moz-placeholder
			color: $color-text-2

	&:focus:not(:disabled)
		border: 1px solid $green
		color: $color-text-1

		&::-webkit-input-placeholder,
		&:-ms-input-placeholder,
		&::-moz-placeholder,
		&:-moz-placeholder
			color: $color-text-2

	&:disabled
		cursor: not-allowed
		background: $white !important
		opacity: .5
		border: 1px solid $tr

	&--white
		background: $white
		line-height: 18px

		&:hover:not(:disabled),
		&:focus:not(:disabled)
			border: 1px solid $red



.checkbox
	display: flex
	align-items: center
	cursor: pointer

	[type="checkbox"]
		display: none

		&:checked
			~
				.checkbox__indicator
					background: #56AF40

					.icon
						transform: scale(1)

		&:disabled

			~
				.checkbox__indicator
					cursor: not-allowed
					background: #E0E0E0 !important

				.checkbox__description
					color: $color-text-1
					cursor: not-allowed

	&__indicator
		background: #E7F1E5
		height: 20px
		width: 20px
		min-width: 20px
		border-radius: 6px
		display: flex
		align-items: center
		justify-content: center
		box-sizing: border-box
		overflow: hidden
		transition: background ease .3s, border ease .3s

		.icon
			color: #ffffff !important
			font-size: 7px

	&__description
		font-size: 12px
		line-height: 100%
		color: #000000
		padding-left: 14px
		flex: 1
		user-select: none

		a
			text-decoration: underline

	&--red
		.checkbox__description
			font-family: $ffs
			color: $red


	&__commercial
		color: #bbb7b7 !important
		
.radio
	display: inline-flex
	align-items: center
	cursor: pointer

	[type="radio"]
		display: none

		&:checked
			~
				.radio__indicator:after
					background: #56AF40
					top: 3px
					bottom: 3px
					left: 3px
					right: 3px

				.radio__description
					color: #333333

		&:disabled
			~
				.radio__indicator
					cursor: not-allowed
					border: 2px solid rgba(0, 0, 0, 0.1) !important
					background: rgba(0, 0, 0, 0.1)

					&:after
						background: #F6F7F9 !important

				.radio__description
					color: rgba(0, 0, 0, 0.6)
					cursor: not-allowed

	&__indicator
		background: $white
		display: flex
		height: 19px
		width: 19px
		min-width: 19px
		border: 2px solid #56AF40
		border-radius: 50%
		overflow: hidden
		position: relative
		display: block

		&:after
			content: ""
			position: absolute
			top: 0
			bottom: 0
			left: 0
			right: 0
			border-radius: 50%
			background: $white
			display: block
			transition: top ease .3s, bottom ease .3s, left ease .3s, right ease .3s,

	&__description
		// font-family: $ffm
		font-size: 14px
		color: #828282
		padding: 0 14px
		flex: 1
		user-select: none



.textarea
	min-height: 60px
	min-width: 100%
	max-width: 100%
	width: 100% !important



.form-default

	.btn
		width: max-content

	&__title
		font-size: 12px
		line-height: 100%
		width: max-content

	&__row
		display: grid
		grid-template-columns: repeat(2, 1fr)
		grid-gap: 16px

	.input-group
		margin-bottom: 27px

	&__send
		margin-top: 35px
		display: flex
		align-items: center
		justify-content: space-between

		button
			margin-right: 50px

			span
				white-space: nowrap

	&__consent
		display: flex
		align-items: center

		a
			color: #000000

			&:hover
				text-decoration: none

	&__label
		margin-bottom: 8px

	&__container
		display: flex
		align-items: center
		width: 100%
		margin-bottom: 20px


	&__wrapper
		margin-bottom: 24px

		&--border
			width: 100%
			padding-top: 12px
			border-top: 1px solid #EAEFF2

	&__name
		font-size: 16px
		line-height: 20px

	&__center
		margin-bottom: 25px

		.form-default__title
			margin: auto

	&__upload
		display: grid
		grid-template-columns: repeat(5, 40px)
		grid-gap: 10px
		margin-top: 16px



.form-seminar
	width: 100%
	max-width: 260px

	&__title
		margin-bottom: 8px

	.input-group
		margin-bottom: 8px

		.input
			background: $white



.form-lk
	margin-bottom: 30px

	.input-group
		margin-bottom: 16px

	&__consent
		display: flex
		align-items: center
		margin-bottom: 35px

		a
			color: #000000

			&:hover
				text-decoration: none
