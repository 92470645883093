.choose-city
  display: flex
  align-items: center
  flex-direction: column
  margin-bottom: 26px

  &__inner
    display: flex
    width: 100%
    align-items: center

  &__selected
    margin-left: 16px
    font-family: $ffs
    font-size: 14px

  &__btn
    +reset_btn
    font-family: $ff
    font-weight: normal
    margin-left: auto
    max-width: 110px
    margin-left: 8px
    border-radius: 10px
    color: #FFFFFF
    border-radius: 5px
    background-color: #56AF40

  &__form
    margin-top: 10px
    width: 100%
