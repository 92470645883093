.page--contacts

	.section-home__container
		transform: translateX(-90px)

	.card-wrapper
		margin-bottom: 8px

		&:last-child
			margin-bottom: 0

		&--green

			.card-wrapper__title
				color: #BBDFB3

	+max-screen(1320px)

		.section-home__container
			transform: translateX(0)
