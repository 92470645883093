.seo-title
	font-family: inherit
	font-size: inherit
	font-weight: inherit
	line-height: inherit
	margin: 0
	padding: 0


.title
	font-family: $ffs
	font-size: 20px
	line-height: 24px


.subtitle
	font-family: $ffs
	font-size: 16px
	line-height: 20px


.strong
	font-family: $ffs


.text-green
	color: $green


.text-gray
	color: $gray

.text-gray-primary
	color: $color-text-2

.text-gray-second
	color: #828282


.typography

	h2
		font-family: $ffs
		font-size: 20px
		line-height: 24px
		margin: 0 0 16px

	h3
		font-family: $ffs
		font-size: 16px
		line-height: 20px
		margin: 0 0 16px

	p:not(:last-child)
		margin-bottom: 18px

	ul
		margin-bottom: 18px
		padding-left: 20px
		list-style-type: disc

		li
			margin-bottom: 16px

	img
		height: 100%
		width: 100%
		max-height: 280px
		object-fit: cover
		border-radius: 10px
		margin-bottom: 20px
