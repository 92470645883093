.header
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1)


.header-top-wrapper
	background: #F4F4F4

	+max-screen(1100px)
		display: none


.header-top
	display: flex
	justify-content: space-between
	padding-top: 15px
	padding-bottom: 15px

	&__info
		display: flex
		font-size: 14px
		line-height: 130%

		&-item
			display: flex
			align-items: center
			position: relative
			margin-right: 30px
			text-decoration: none
			color: $color-text-1
			transition: color ease .3s
			font-size: 14px
			line-height: 130%
			cursor: pointer

			.icon
				color: $green

				&-location
					font-size: 18px
					margin-right: 6px

				&-down-1
					font-size: 5px
					color: #BBDFB3
					margin-left: 4px

				&-arrow-bottom
					margin-left: 4px
					font-size: 10px

			&:last-child
				margin-right: 0

			&:hover
				color: $green

				.tooltip
					opacity: 1
					pointer-events: all

	+max-screen(1220px)

		&__info-item
			margin-right: 20px



.header-main
	display: flex
	align-items: center
	justify-content: space-between
	padding: 20px 0
	border-bottom: 1px solid #DDE5EA
	position: relative

	&__logo
		height: 40px
		margin-right: 20px

		img
			position: relative
			top: 50%
			transform: translateY(-50%)

	&__burger
		display: none
		position: relative

	&__btn-mobile
		display: none
		padding: 0
		width: 24px
		height: 24px

		.icon
			color: #000000
			font-size: 24px

	+min-screen(1100px)
		&__mobile.nav
			display: none !important

		&__logo
			display: inline !important

		&__search
			width: 100%

	+max-screen(1100px)
		padding: 12px 0
		border-bottom: 1px solid $tr

		&__mobile.nav
			display: none

		&__logo
			height: 24px
			margin-right: 0

			img
				height: 100%
				width: auto
				object-fit: contain
				position: absolute
				transform: translate(-50%, -50%)
				left: 50%

		&__burger,
		&__btn-mobile
			display: block

		&__btn-mobile
			margin-right: 0

		.header-main__form-search.form-search,
		.header-main__nav.nav
			display: none



.header-menu
	display: flex

	&__wrapper
		display: flex
		justify-content: space-between
		border-top: 1px solid #DDE5EA
		padding-top: 30px

	&__item
		display: flex
		color: $color-text-1
		text-decoration: none
		cursor: pointer
		margin-right: 50px
		padding: 18px 0
		border-bottom: 4px solid $tr
		transition: border-bottom ease .3s, opacity ease .3s

		.icon
			margin: auto 0 auto 5px
			color: #BBDFB3
			font-size: 5px
			transition: transform ease .6s

		&-img
			margin-left: 9px

		&-body
			display: none
			position: absolute
			left: 0
			width: 100%
			background: #fff
			padding-bottom: 30px
			box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.09)
			transform: translateY(42px)
			z-index: 999

			&-left
				display: flex
				grid-gap: 120px
				width: 70%

		&:hover
			color: #000
			border-bottom: 4px solid $green

			.icon
				transform: rotate(180deg)

			.header-menu__item-body
				display: flex

	+max-screen(1220px)

		&__item
			margin-right: 30px

			&-body-left
				grid-gap: 80px


	+max-screen(1100px)
		display: none



.list
	list-style-type: none
	padding: 0
	margin: -8px 0
	font-size: 14px
	line-height: 130%

	&__item
		padding: 8px
		transition: color ease .3s

		&:hover
			color: $green



.stock
	display: flex
	flex-direction: column

	&__title
		font-family: $ffs
		margin-top: 12px


.header-mobile
	display: none
	position: fixed
	top: 49px
	left: 0
	right: 0
	bottom: 0
	overflow: hidden
	overflow-y: auto
	opacity: 0
	width: 100%
	height: 100vh
	padding-top: 35px
	padding-bottom: 35px
	border-top: 1px solid #BACAD5
	background: $white
	pointer-events: none
	transform: translateY(-49px)
	transition: transform ease .3s, opacity ease .3s
	z-index: 9999

	&__row
		display: flex
		justify-content: space-between
		align-items: center

	&.active
		opacity: 1
		pointer-events: initial
		transform: translateY(0)

	+max-screen(1100px)
		display: block

	+max-screen(767px)
		padding-top: 0
		border-top: 1px solid #F2F2F2

		&__row
			display: none



.header-mobile-link
	display: none

	+max-screen(767px)
		display: block
		position: fixed
		bottom: 0
		background: #fff
		width: 100%
		left: 0
		right: 0
		z-index: 1000
		box-shadow: 0px -1px 10px rgba(40, 37, 33, 0.03)
		filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.1))
		border-radius: 15px 15px 0px 0px



.header-search
	display: none
	position: absolute
	width: 100%
	background: #fff
	padding: 10px
	z-index: 111

	&__form
		display: flex
		grid-gap: 10px
		position: relative

	&__close
		position: absolute
		right: 0
		padding: 11px

		.icon
			font-size: 12px
			color: #000



.header-info
	position: relative
	background: #BBDFB3
	display: flex
	justify-content: center
	align-items: center
	height: 48px
	gap: 20px

	&::after,
	&::before
		content: ''
		position: absolute
		top: 0
		height: 100%
		width: 610px

	&::after
		right: 0
		background: no-repeat url('/img/flower.png')
		
	&::before
		left: 0
		background: no-repeat url('/img/flower1.png')

	&__btn
		width: max-content

	.subtitle
		z-index: 1

	+max-screen(1280px)
		&::after,
		&::before
			width: 300px

	+max-screen(767px)
		.subtitle
			font-size: 14px