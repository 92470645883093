.body
	display: flex
	flex-direction: column
	justify-content: space-between
	min-height: 100vh


.page
	flex: 1
	margin-bottom: 45px


.container
	max-width: 1300px
	width: 100%
	margin: 0 auto

	+max-screen(1320px)
		padding: 0 30px

	+max-screen(768px)
		padding: 0 16px
