.accordeon
	width: 100%
	max-width: 530px
	border-top: 1px solid #E2EAE9

	&:last-child
		border-bottom: 1px solid #E2EAE9

	.active
		.accordeon-head__btn::before
			transform: rotate(90deg)
			opacity: 0

	&-head
		+reset_btn()
		width: 100%
		display: flex
		align-items: center
		justify-content: space-between
		padding: 20px 0 20px 16px
		transition: background ease .3s, color ease .3s

		&__description
			text-align: start
			
			&-count
				display: inline-flex
				justify-content: center
				border-radius: 20px
				margin-left: 8px
				font-size: 13px
				width: 20px
				min-width: 20px
				height: 20px
				background: $green
				color: $white

		&__value
			font-family: $ffs
			font-size: 14px
			line-height: 20px
			color: #8B928C
			margin-right: 25px

		.icon
			width: 25px
			font-size: 7px
			transition: transform ease .3s

		&:hover
			color: $green

		&.active
			.icon
				transform: rotate(180deg)

	&-body
		display: none
		padding: 0 24px 0 20px

		&__item
			padding: 16px 0 20px 20px
			border-bottom: 1px solid #E2EAE9

			&:first-child
				padding-top: 0

			&:last-child
				border-bottom: none

		&__title
			margin-bottom: 8px
			position: relative

			&::before
				content: ""
				position: absolute
				top: 5px
				left: -20px
				width: 8px
				height: 8px
				border-radius: 8px
				background: $green

		&__contact
			display: grid
			grid-template-columns: 60px 1fr
			grid-gap: 8px
			margin-bottom: 12px

			&:last-child
				margin-bottom: 0

			&-name
				color: #8B928C

		&__description
			display: none
			margin-bottom: 8px

	&--contacts
		max-width: 640px
		border-top: 1px solid $gray

		&:last-child
			border-bottom: 1px solid $gray

		.accordeon

			&-head

				&:hover,
				&.active
					color: $color-text-1
					background: #F3F8F2

			&-body
				background: #F3F8F2

				&__title
					font-family: $ffb

	&--filter

		.accordeon

			&-head
				padding: 20px 16px

				.icon
					width: 24px

				&:hover
					color: $color-text-1
					background: #E7F1E5

			&-body
				padding: 6px 16px 16px

				.checkbox:not(:last-child)
					margin-bottom: 20px

.accordeon-wrapper
	margin-bottom: 25px


.item-dashed
	width: max-content
	color: $green
	border-bottom: 1px dashed $green
	cursor: pointer
	transition: border-color ease .3s

	&:hover
		border-color: $tr

	&.active
		border-color: $tr