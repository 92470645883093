.delivery-banner
  background-color: #F4F4F4
  padding:
    top: 20px
    right: 20px
    bottom: 16px
    left: 20px

  &__head
    display: flex
    align-items: center
    margin-bottom: 16px

  &__icon
    width: 40px
    height: 40px

  &__title
    margin-left: 20px
    font-family: $ffs
    font-size: 16px
    color: $color-text-1

  &__text
    font-size: 12px
    color: $color-text-1
    font-family: $ff
