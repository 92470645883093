.modals
	display: none

.mfp-close
	display: none !important

.mfp-bg
	background: rgba(0, 0, 0, 0.3)



.modal
	max-width: 700px
	margin-left: auto
	margin-right: auto
	width: 100%
	padding: 55px 76px 40px
	background-color: #fff
	color: #000000
	position: relative
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.32)
	border-radius: 10px

	&__close
		+reset_btn
		position: absolute
		top: 12px
		right: 12px
		display: flex
		padding: 6px
		align-items: center
		justify-content: center

		.icon
			font-size: 12px
			color: #000000

	&__title
		text-align: center
		font-family: $ffs
		font-size: 20px
		line-height: 24px

	&__description
		margin: 0 auto

	&__content
		margin-top: 45px

	&__btn
		width: max-content
		margin: auto
		padding: 13px 24px

	&__body
		z-index: 1

	.form-default

		&__item

			.input-group
				margin-bottom: 20px

		.input-group--help
			height: 30px

		&__send
			margin-bottom: 20px

		&__consent .input-group
			display: flex
			justify-content: center

	&--message
		max-width: 400px
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center
		padding: 90px 30px
		overflow: hidden

		.modal__title
			margin-bottom: 24px

		.modal__description
			max-width: 325px
			text-align: center

			&:last-child
				margin-bottom: 0

	&--success
		position: relative

		.modal__title,
		.modal__description
			margin-bottom: 12px

		&::before,
		&::after
			content: ""
			position: absolute

		&::before
			width: 177px
			height: 56px
			top: 6px
			right: 67px
			background: no-repeat url("/img/green-flowers.png")

		&::after
			width: 186px
			height: 100px
			bottom: 5px
			left: 48px
			background: no-repeat url("/img/green-flowers1.png")

	&--error

		.modal__title
			color: $red

	&--city
		max-width: 400px
		padding: 30px 30px 5px

		.modal__title
			margin-bottom: 24px

	&--form
		max-width: 290px
		padding: 32px 16px

		.form-default__consent
			margin-bottom: 36px

	&--review
		max-width: 532px
		padding: 32px 16px

		.modal__body
			width: 100%

		.form-default
			display: flex
			flex-direction: column
			align-items: center

			&__container
				.switch
					margin-right: 11px

			&__row,
			&__item
				width: 100%



.result-list
	height: 300px
	margin: 0 -30px
	padding: 0 30px

	&__item
		cursor: pointer
		padding: 10px 0
		transition: color ease .3s

		&:hover
			color: $green

		&--main
			font-family: $ffb

		&--select
			font-family: $ffb
			color: $green

	.simplebar-scrollbar
		&:before
			background-color: $green

	.simplebar-track
		right: 8px

		&.simplebar-vertical
			width: 4px
