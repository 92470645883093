.main-basket

  //.input-group[data-property-id-row='56'],
  //.input-group[data-property-id-row='54'],
  //.input-group[data-property-id-row='53']
  //  display: none

  &__title
    margin-bottom: 16px
    font-family: $ffb
    font-size: 24px

  *:before:not(.radio),
  *:after:not(.radio)
    content: none !important

  .bx-soa-pp-company,
  .order-step__promo-title,
  .order-location-notification,




  .bx-soa-coupon-input:after
    display: none

  #bx-soa-basket

    .bx-soa-section-title-container
      display: none

    .bx-soa-section-content

      &__delivery-banner
        margin-bottom: 20px

  .input

    &-group__inner
      display: flex

      .btn
        max-width: 110px
        margin-left: 8px
        border-radius: 10px

    &--textarea
      min-width: 100%
      max-width: 100%
      width: 100%
      min-height: 90px

  .bx-soa
    gap: 130px
    display: flex
    align-items: flex-start

    +max-screen(1280px)
      gap: 100px

    +max-screen(1024px)
      gap: 30px

    +max-screen(475px)
      flex-wrap: wrap
      gap: 0

    #bx-soa-total-mobile,
    .bx-soa-cart-total-button-container.visible-xs
      display: none

    &__column

      &:nth-child(1)
        width: 530px

        +max-screen(1024px)
          width: calc(100% / 2)

        +max-screen(475px)
          width: 100%

      &:nth-child(2)
        width: calc(100% - (530px + 130px))
        position: sticky
        top: 0
        left: 0

        +max-screen(1024px)
          width: calc(100% / 2)

        +max-screen(475px)
          width: 100%

      .product-card

        &__body
          display: flex
          flex-direction: column
          align-items: flex-start

          &-right
            align-self: auto
            width: 100%
            margin-top: auto

  .bx-soa-location-input-container
    padding-top: 6px

  .bx_soa_location

    .col-xs-12
      padding: 0

    .dropdown-block
      border: none
      background: transparent
      box-shadow: none

    .dropdown-fade2white
      display: none

  .input-groups

    &--order-person-type
      display: none

  .order-step__delivery-cards.active, .row.bx-soa-more
    display: none

  // Отключение уведомления компонента Bitrix об автоматическом заполнении полей
  .alert
    display: none !important

    &-warning
      display: none !important

  .input-group
    display: flex
    flex-direction: column
    width: 100%
    margin-bottom: 10px

    &__wrapper
      width: 100%
      margin-bottom: 16px

    &__label
      font-size: 14px
      font-family: $ffs

  .row
    margin: 0

  .bx-soa-section
    border: none

    &.bx-step-completed
      border: none

    &-title-container
      background: transparent
      padding: 0

    &-title-count
      display: none

    &-title
      padding-left: 0
      color: $green
      font-size: 16px
      font-family: $ffs
      margin-bottom: 16px

    &-content
      padding: 0

  .bx-price-old
    display: none

  .bx-soa-cart

    &-total
      background-color: #E7F1E5
      padding: 16px 18px

      &-line
        border-top: none
        padding: 0
        margin-bottom: 16px

        &-highlighted
          display: none

        &-total
          padding-top: 16px
          border-top: 1px solid #BACAD5
          margin-bottom: 0

          .bx-soa-cart-t
            font-family: $ff
            font-size: 14px
            font-weight: normal
            color: #5B5B5B !important

          .bx-soa-cart-d
            font-family: $ffb
            font-size: 14px !important

    &-t
      font-family: $ff
      font-size: 12px
      color: $color-text-2
      font-weight: normal !important

    &-d
      font-family: $ff
      font-size: 14px
      color: $color-text-1

      .bx-soa-price-free
        color: inherit

      &:first-letter
        text-transform: capitalize

  .visible-sm
    display: none !important

  .bx-soa-item

    &-content

      +max-screen(475px)
        padding-top: 0
        margin-left: 20px

    &-title
      font-size: 16px
      font-family: $ffs
      margin-bottom: 12px

    &-img-block
      position: absolute
      left: 0

      a
        display: flex
        width: 60px
        height: 60px

      +max-screen(475px)
        position: relative
        margin: 0

    &-imgcontainer
      border-radius: 10px
      border: none
      width: 60px
      height: 60px

    &-tr
      display: flex
      flex-direction: column
      width: 100%
      padding-bottom: 20px
      margin-bottom: 20px
      border-bottom: 1px solid rgba(#BACAD5, .3)

      .bx-soa-item-td

        &:nth-child(1)
          order: 1

        &:nth-child(3)
          order: 2
          margin-bottom: 12px

        &:nth-child(2)
          order: 3


    &-td
      border: none
      padding: 0
      width: calc(100% - 72px) !important
      margin-left: auto

      &-text
        display: flex !important
        align-items: center

        .delete
          position: absolute
          right: 0

          span
            position: relative

            &:before
              content: ''
              position: absolute
              top: 0
              right: 0
              display: block
              width: 15px
              height: 2px
              background-color: #000
              transform: rotate(45deg)

            &:after
              content: ''
              position: absolute
              top: 0
              right: 0
              display: block
              width: 15px
              height: 2px
              background-color: #000
              transform: rotate(-45deg)

        .minus
          background-color: #BACAD5
          color: $white
          width: 24px
          height: 24px
          display: flex
          align-items: center
          justify-content: center
          border-radius: 7px

        .plus
          background-color: #BACAD5
          color: $white
          width: 24px
          height: 24px
          display: flex
          align-items: center
          justify-content: center
          border-radius: 7px

        input[type='text']
          background: none
          border: none
          outline: none
          width: 40px
          padding: 0 12px
          font-size: 16px
          color: #000
          text-align: center

      +max-screen(475px)
        margin-left: 0

    &-block

      +max-screen(475px)
        display: flex

    &-properties

      +max-screen(475px)
        margin-left: 70px

.bx-soa-pp-company

  .radio

    &__description
      display: flex
      flex-direction: column

      &-type
        font-family: $ffs
        font-size: 14px
        color: rgba($color-text-1, .7)
        margin-bottom: 8px

      &-price
        font-family: $ffs
        font-size: 12px
        color: rgba($color-text-1, .7)
        margin-bottom: 8px

      &-time
        font-family: $ff
        font-size: 12px
        margin-bottom: 8px
        color: rgba($color-text-1, .7)

      &-adress
        font-family: $ff
        font-size: 12px
        margin-bottom: 8px
        color: rgba($color-text-1, .7)

.d-none
  display: none !important
