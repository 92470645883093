.footer

	&__top
		border-top: 1px solid #DDE5EA

	&__bottom
		font-size: 12px
		line-height: 130%
		border-top: 1px solid #DDE5EA

	&__container,
	&__row
		display: flex
		justify-content: space-between
		padding: 20px 0

	&__row
		align-items: baseline

	&__dev
		display: flex
		align-items: baseline
		text-decoration: none
		color: #000000

		&:hover
			color: #000000

		&-label
			margin-right: 15px

	+max-screen(1280px)

		&__container
			flex-direction: column

	+max-screen(767px)

		&__bottom
			.footer__row
				padding: 20px 0 77px

	+max-screen(500px)

		&__row
			flex-direction: column

		&__dev
			width: 100%
			justify-content: space-between
			margin-top: 24px



.footer-navigation
	display: flex
	align-items: flex-start

	&__wrapper
		display: flex
		justify-content: space-between
		align-items: center

		.icon
			display: none
			font-size: 7px
			transition: transform ease .3s

	&__column
		width: 250px

	&__list
		max-width: 150px
		margin: -9px 0

	&__link
		display: flex

	+max-screen(1280px)
		justify-content: space-between
		margin-bottom: 45px

	+max-screen(1024px)

		&__column
			width: 290px

	+max-screen(768px)
		flex-direction: column
		margin-bottom: 40px

		&__column
			width: 100%
			margin-right: 0
			margin-bottom: 20px

			&:last-child
				margin-bottom: 0

		&__list
			display: none
			max-width: 100%
			margin-top: 0

		&__wrapper
			cursor: pointer

			&.active
				padding-bottom: 15px
				
				.icon
					transform: rotate(180deg)

			.icon
				display: block

			.footer-navigation__title
				margin-bottom: 0







.footer-navigation,
.footer-contacts

	&__title
		color: $color-text-1
		display: flex
		margin-bottom: 27px

	&__column

		li
			font-size: 14px
			line-height: 130%

	&__link
		padding: 9px 0




.footer-contacts
	display: grid
	grid-template-rows: repeat(2, max-content)
	grid-template-columns: repeat(2, 1fr)
	grid-gap: 30px

	&__link
		display: grid
		grid-template-columns: 16px 1fr
		grid-gap: 8px

		span
			grid-area: 1/2

	&__list
		max-width: 200px
		margin: -9px 0

		.link
			font-size: 12px
			line-height: 20px

		.icon
			font-size: 16px
			color: $gray
			margin-right: 9px

	&__logos
		display: flex

	&__logo
		margin-right: 25px

		&:last-child
			margin-right: 0

		&-link

			&:not(:last-child)
				margin-right: 12px

			.icon
				font-size: 32px
				color: $gray
				transition: opacity ease .3s

			&:hover

				.icon
					opacity: .7

	+max-screen(1280px)
		grid-template-columns: repeat(2, max-content)
		grid-gap: 40px

		&__column
			width: 290px

	+max-screen(768px)
		grid-template-rows: repeat(4, max-content)
		grid-template-columns: 1fr

		&__column,
		&__list
			width: 100%
			max-width: 100%
