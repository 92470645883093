.tabs
	display: flex

	&__item

		&:not(:last-child)
			margin-right: 15px

		&:hover
			color: inherit

		&--active

			.checkbox-tabs__description
				background: #BBDFB3



.tabs-wrapper
	overflow: auto



.checkbox-tabs
	cursor: pointer

	input[type="checkbox"]
		display: none

		&:checked ~ .checkbox-tabs__description
			background: #BBDFB3

	&__description
		display: block
		width: max-content
		padding: 8px 12px
		background: $color-bg-1
		border-radius: 10px
		transition: background ease .3s

		&:hover
			background: #E7F1E5
