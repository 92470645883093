.wrap-preloader
	position: fixed
	top: 0
	left: 0
	right: 0
	bottom: 0
	background-color: rgba(0, 0, 0, 0.3)
	z-index: 99999
	display: flex
	align-items: center
	justify-content: center

	.loader
		position: fixed
		top: 50%
		left: 50%
		transform: translateX(-50%) translateY(-50%)



.section-preloader
	position: relative

	&__wrapper
		position: absolute
		top: 0
		left: -1000px
		right: -1000px
		bottom: 0
		background: transparent
		z-index: -1
		display: flex
		z-index: 99999
		align-items: center
		justify-content: center
		pointer-events: none
		transition: background ease .3s

	.loader
		position: relative
		transform: scale(0)
		transition: transform ease .3s

	&--show

		.section-preloader__wrapper
			pointer-events: auto
			background: rgba(0, 0, 0, 0.3)

		.loader
			transform: scale(1)



$size: 10px
$duration: 1s


.loader
	font-size: $size
	width: 44px
	height: 44px
	border-radius: 100%


	&:before,
	&:after
		content: ""
		position: absolute
		top: -10px
		left: -10px
		width: 100%
		height: 100%
		border-radius: 100%

	&:before
		z-index: 100
		animation: spin $duration infinite
		border: 7px solid transparent
		border-top-color: $green
		border-left-color: $green

	&:after
		border: 7px solid rgba(231, 241, 229, 0.3)


@keyframes spin
	0%
		-webkit-transform: rotate(0deg)
		-ms-transform: rotate(0deg)
		-o-transform: rotate(0deg)
		transform: rotate(0deg)


	100%
		-webkit-transform: rotate(360deg)
		-ms-transform: rotate(360deg)
		-o-transform: rotate(360deg)
		transform: rotate(360deg)

