@mixin fontFace($fontName)
	@font-face
		font-family: quote($fontName)
		src: url("../fonts/"+ $fontName +"/" $fontName + ".eot")
		src: url("../fonts/"+ $fontName +"/" + $fontName + ".eot?#iefix") format("embedded-opentype"), url("../fonts/"+ $fontName +"/" + ".woff") format("woff"), url("../fonts/"+ $fontName +"/" + $fontName + ".ttf") format("truetype"), url("../fonts/"+ $fontName +"/" + $fontName + ".svg##{$fontName}") format("svg")
		font-weight: normal
		font-style: normal

@mixin fontFaceTtf($fontName)
	@font-face
		font-family: quote($fontName)
		src:  url("../fonts/"+ $fontName +"/" + $fontName + ".ttf") format("truetype")
		font-weight: normal
		font-style: normal
		font-display: fallback

+fontFaceTtf("Gilroy-Bold")
+fontFaceTtf("Gilroy-Bold-Italic")
+fontFaceTtf("Gilroy-Medium")
+fontFaceTtf("Gilroy-Medium-Italic")
+fontFaceTtf("Gilroy-SemiBold")
+fontFaceTtf("Gilroy-SemiBold-Italic")
