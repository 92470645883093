.page--brands,
.page--private-label

	.tabs-wrapper
		margin-bottom: 20px


.page--private-label

	.grid-container__description
		width: 100%
		max-width: 640px

	.swiper-slide
		width: 100% !important


.section-columns
	display: grid
	grid-template-columns: repeat(2, 1fr)
	grid-gap: 20px

	+max-screen(1024px)
		grid-template-columns: 1fr


.section-column
	display: grid
	grid-template-columns: repeat(3, max-content)
	grid-gap: 12px

	+max-screen(1022px)
		grid-template-columns: repeat(2, max-content)

	+max-screen(680px)
		grid-template-columns: 1fr

		.button-save
			max-width: 100%
