.page--private-club
	.grid-home
		grid-column-gap: 40px

	.section-home

		&__header
			display: block

		&__title
			width: 100%
			margin-bottom: 16px



.section-delivery
	width: 100%
	max-width: 640px
	padding-bottom: 25px
	border-bottom: 1px solid #EAEFF2
	margin-bottom: 25px

	&:last-child
		padding-bottom: 0
		border-bottom: none
		margin-bottom: 0

	&__title
		color: $green
		margin-bottom: 20px

	&__subtitle
		color: $red
		font-size: 12px
		line-height: 18px
		margin-bottom: 16px

	&__cards
		display: grid
		grid-template-columns: repeat(2, 1fr)
		grid-gap: 18px

	&__list-item
		margin-bottom: 18px

		&:last-child
			margin-bottom: 0

	&__text
		margin-bottom: 8px

		&-content
			color: #5B5B5B
			margin-right: 12px

			&:last-child
				margin-right: 0

		&-value
			color: #AAAAAA

	+max-screen(700px)

		&__cards
			grid-template-columns: 1fr



.card-wrapper
	background: $color-bg-1
	border-radius: 10px
	padding: 16px

	&__title
		margin-bottom: 16px

	&__subtitle
		margin-bottom: 20px

	&__container
		width: 100%
		max-width: 310px
		padding: 20px 40px
		margin: auto

	&__text
		padding: 4px 8px
		border-radius: 10px
		font-size: 12px
		line-height: 18px
		width: max-content

		&--light
			background: $white
			color: $color-text-2
			margin-bottom: 16px

		&--green
			background: #BBDFB3
			font-family: $ffs

		&--strong
			background: $white
			font-family: $ffs

	&__table
		margin: 0 -6px

		td
			vertical-align: top
			padding: 4px 6px

		thead
			td
				font-family: $ffb
				font-size: 16px
				line-height: 18px
				color: $color-text-2

	&__link
		display: flex
		justify-content: center
		align-items: center
		font-family: $ffb
		font-size: 14px
		line-height: 14px
		margin: 0 auto 26px

		.icon
			font-size: 16px
			color: $green
			margin-left: 6px

	&__btn
		margin: 26px 0

		&-save
			padding: 11px 45px
			text-align: left

			img
				margin-right: 10px

		&-login
			padding: 0
			align-items: center

			.button-save

				&__icon
					.icon
						color: $color-text-2

			&:hover

				.button-save__icon
					background: $white
					.icon
						color: $red

	&__content
		display: grid
		grid-template-columns: repeat(2, 1fr)
		grid-gap: 70px

	&__phone
		color: $white
		font-family: $ffs
		font-size: 20px
		line-height: 40px

		&:hover
			color: $white

	&__header
		display: flex
		margin-bottom: 16px

		.text-gray-second
			margin-top: 12px
			line-height: 14px

	&__img
		height: 80px
		width: 80px
		object-fit: cover
		border-radius: 10px
		margin-right: 12px

	&__form

		.input
			background: $white

		.input-group
			margin-bottom: 20px

		.form-default__consent
			margin-bottom: 36px

		.btn
			width: 100%

		&--hidden
			display: none
			padding-top: 12px
			border-top: 1px solid $white

	&--green
		background: $green
		color: $white

		.card-wrapper

			&__title
				margin-bottom: 20px

			&__value
				font-family: $ffb
				font-size: 50px
				line-height: 100%

			&__text
				font-size: 16px
				line-height: 18px
				color: #BBDFB3
				margin: auto

	&--form
		padding: 0
		height: max-content

		.card-wrapper

			&__title,
			&__subtitle
				text-align: center

	&--default
		width: 100%
		max-width: 310px

	&--order
		width: 100%
		max-width: 640px
		margin-bottom: 20px

		&:last-child
			margin-bottom: 0

		.table
			width: 100%
			max-width: 250px

	+max-screen(670px)
		&--order

			.products
				display: none

		&--form

			.card-wrapper

				&__container
					padding: 20px 16px

	+max-screen(630px)

		&__content
			grid-template-columns: 1fr
			grid-gap: 30px

		&__btn-save
			max-width: 100%



.card-row
	display: flex
	justify-content: space-between
	align-items: center
	margin-bottom: 16px

	&:last-child
		margin-bottom: 0

	.btn
		width: max-content
		padding: 0

	&--top
		align-items: flex-start


.section-payment
	width: 100%
	max-width: 640px

	&__text
		margin-bottom: 45px

		a
			text-decoration: underline

	&__list
		margin-bottom: 30px

	&__logos
		display: flex

	&__logo
		margin-right: 25px

		&:last-child
			margin-right: 0


.list-green
	padding-left: 20px

	&__item
		width: 100%
		max-width: 270px
		margin-bottom: 8px
		position: relative

		&::before
			content: ""
			position: absolute
			top: 5px
			left: -20px
			width: 8px
			height: 8px
			border-radius: 8px
			background: $green


.stocks-container
	display: grid
	grid-template-columns: repeat(4, 1fr)
	grid-gap: 20px

	.stock__title
		font-size: 20px
		line-height: 24px

	+max-screen(1150px)
		grid-template-columns: repeat(3, 1fr)

	+max-screen(900px)
		grid-template-columns: repeat(2, 1fr)

	+max-screen(600px)
		grid-template-columns: 1fr



.btn-stock
	width: max-content
	padding: 13px 82px
	margin: auto



.section-front

	&__img
		width: 50%
		height: 100%
		object-fit: contain
		border-radius: 10px
		margin-bottom: 45px

	&__text
		margin-bottom: 20px

	+max-screen(767px)
		&__img
			width: 100%



.grid-container-wrapper
	display: grid
	grid-template-columns: repeat(2, max-content)
	grid-gap: 20px

	&--lk
		align-items: flex-end

		.title
			width: 100%
			max-width: 280px
			margin-bottom: 20px

	+max-screen(1340px)
		grid-template-columns: 1fr



.grid-content

	+max-screen(1023px)
		width: 100%
		max-width: 640px
		margin: auto



.grid-container
	display: grid
	grid-template-columns: 310px 640px
	grid-gap: 20px
	margin-bottom: 25px

	&:last-child
		margin-bottom: 0

	.btn
		height: max-content

	&__btn
		max-width: 290px

	&__left
		display: grid
		grid-gap: 16px
		height: max-content

		.btn
			font-family: $ff
			padding: 18px 30px
			width: 100%
			max-width: 255px

	&__description
		grid-column: 2

	&__wrapper
		.card-wrapper:not(:last-child)
			margin-bottom: 20px

	&__cards
		display: grid
		grid-template-columns: repeat(2, 1fr)
		grid-gap: 10px

		.card-wrapper

			&:first-child
				grid-column: 1/3

	&__search
		max-width: 100%
		margin-bottom: 30px

	&--border

		.grid-container__description
			padding-top: 30px
			border-top: 1px solid #DCE4EA

	+max-screen(1023px)
		grid-template-columns: 1fr

		&__description
			grid-column: 1

		&__title
			margin-bottom: 16px

		&__btn,
		&__left
			margin-bottom: 32px

		&__left
			grid-template-columns: repeat(2, max-content)


	+max-screen(630px)

		&__cards
			grid-template-columns: 1fr

			.card-wrapper

				&:first-child
					grid-column: 1

	+max-screen(500px)

		&__left
			grid-template-columns: 1fr



.table
	display: grid
	grid-template-columns: max-content 1fr
	grid-gap: 16px
	margin-bottom: 16px

	&:last-child
		margin-bottom: 0
